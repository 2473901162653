import tw from 'twin.macro'
import React from 'react'

interface Props {
  onClose: () => void
  onSignUp: () => void
}
export function SignUpModal({ onClose, onSignUp }: Props) {
  return (
    <Overlay>
      <div
        tw="flex flex-col items-center rounded-2xl bg-white p-10 w-7/12 text-2xl leading-10"
        css="max-width: 512px; min-width: 350px;"
      >
        <div tw="flex justify-end w-full cursor-pointer" onClick={onClose}>
            <img src="/icon_close2.svg" tw="w-8" />
          </div>
        <div tw="flex flex-col justify-between items-center" css="max-width: 412px;">
          <h1 tw="flex-1 text-center text-5xl">飲食店登録</h1>
          <div tw="mt-14 text-justify">
            <p tw="">飲食店様へのサービス対象エリアは以下になります。</p>
            <p tw="mt-4">世田谷区・品川区・千代田区・新宿区・渋谷区・港区・中央区・目黒区・大田区</p>
            <p tw="mt-10 font-bold">
              上記のエリア以外の飲食店様は、ご登録頂いても現時点では商品のご購入・お届けはできかねますのでご了承下さい。
            </p>
          </div>
          <p tw="mt-10 whitespace-pre-wrap text-xl font-bold text-red landing-pc:text-2xl">
            {`※こちらは飲食店様登録専用フォームです。\n生産者様登録の場合は下記までご連絡ください。\n`}
          </p>
          <div tw="mt-10">
            <ButtonOrange
              onClick={onSignUp}
              css="padding: 10px 80px; font-weight: 700; font-size: 16px; transition: all .3s; border: solid 2px #FC771C;"
            >
              登録へ進む
            </ButtonOrange>
          </div>
          <div tw="mt-10 text-20px whitespace-pre-wrap leading-10">
            <section >
              <h3>ヤマトフードマーケット事務局</h3>
              <a href="mailto:yfm0301@kuronekoyamato.co.jp" tw="flex gap-2" css="width: fit-content">
                <img src="/landing_renewal/img/icon_mail_orange.svg" alt="mail" width="12" />
                yfm0301@kuronekoyamato.co.jp
              </a>
              <a href="tel:03-4564-2161" tw="flex gap-2" css="width: fit-content">
                <img src="/landing_renewal/img/icon_tell_orange.svg" alt="tel" width="12" />
                03-4564-2161
              </a>
            </section>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-10 bg-black bg-opacity-75`
const ButtonOrange = tw.button`bg-orange text-white rounded-full hover:bg-white hover:text-orange`
